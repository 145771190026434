import './WeddingVideo.css'
import {useEffect, useRef} from "react";
import Player from "@vimeo/player";

const WeddingVideo = ({videoId, onEnded}) => {
  const videoContainer = useRef()
  const options = {
    id: videoId,
    autoplay: false,
    autopause: true,
    width: window.innerWidth,
    title: false,
    transparent: true,
    byline: false,
  };

  useEffect(() => {
    const player = new Player(videoContainer.current, options);
    player.on('ended', () => {
      player.loadVideo(options.id)
      onEnded && onEnded(player)
    });
  // eslint-disable-next-line
  }, [])

  return (
    <div className="video-responsive" ref={videoContainer}></div>
  )
}

export default WeddingVideo
