import './Authentication.css';
import {onAuthChanged, signAppOut, signInWithGoogle} from "../../services/authentication";
import {useEffect, useState} from "react";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import WeddingMenuItem from "../wedding-menu-item/Wedding-Menu-Item";

const Authentication = ({onLogin, onLogout}) => {

  const [user, setUser] = useState();
  useEffect(() => {
    return onAuthChanged(user => {
      setUser(user)
      onLogin && onLogin(user)
    })
  // eslint-disable-next-line
  }, [])

  const login = async () => {
    const user = await signInWithGoogle()
    setUser(user)
    onLogin && onLogin(user)
  }

  const logout = async () => {
    await signAppOut()
    setUser()
    onLogout && onLogout()
  }

  const iconStyle = {
    color: "black"
  }

  return (
    <>
      { !user && (
        <WeddingMenuItem onClick={login} to="#" icon={<LoginIcon style={iconStyle} />} text="Login"/>
      )}
      { !!user && (
        <>
          <WeddingMenuItem onClick={logout} to="#" icon={<LogoutIcon style={iconStyle} />} text="Logout"/>
        </>
      )}
    </>
  )
}

export default Authentication;
