import {Box, Link, Typography} from "@mui/material";

const AccommodationItem = ({location, rooms, link}) => {
  const normalizeLinkHref = (link) => {
    if (link.indexOf("http") === 0){
      return link
    }else{
      return `https://${link}`
    }
  }
  const normalizeLinkText = (name) => {
    return name.replace(/https?:\/\//,"")
  }
  return (
    <Box>
      <Typography sx={{fontWeight: "bold"}}>{location}</Typography>
      <Typography>{rooms}</Typography>
      <Link href={normalizeLinkHref(link)} target="_blank" rel="noreferrer">{normalizeLinkText(link)}</Link>
    </Box>
  )
}

export default AccommodationItem
