import './Carousel.css'
import {Fragment} from 'react'
import Flickity from "react-flickity-component";
import CarouselItem from "./Carousel-Item";

const Carousel = ({data}) => (
  <Fragment>
    <Flickity
      className="carousel"// default ''
      elementType="div" // default 'div'
      options={{
        autoPlay: true,
        selectedAttraction: 0.01,
        friction: 0.15,
        pageDots: false,
        prevNextButtons: false,
        freeScroll: true,
        freeScrollFriction: 0.03,
        wrapAround: true,
        initialIndex: 3
      }} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      {data && data.map((it, index) => (
        <CarouselItem key={index} src={it.src} text={it.text} subText={it.subText}/>
      ))}
    </Flickity>
  </Fragment>
)

export default Carousel
