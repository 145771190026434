import AccommodationItem from "./Accommodation-Item";
import {Grid, Typography} from "@mui/material";

const AccommodationList = ({title, data}) => {
  return (
    <Grid container spacing={2} sx={{mt: 8}} textAlign="center" justifyContent="center">
      <Grid item xs={12}>
        <Typography component="h1" variant="janetH1">{title}</Typography>
      </Grid>
      {
        data && data.map((it, index) => {
          return (
            <Grid key={index} item xs={12} sm={6}>
              <AccommodationItem {...it} />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default AccommodationList
