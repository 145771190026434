import './Accommodation.css';
import {Box, Container, Stack, Typography} from "@mui/material";
import AccommodationList from "../components/accomodation-list/Accommodation-List";
import WeddingDivider from "../components/wedding-divider/Wedding-Divider";
import Header from "../components/header/Header";
import {Fragment} from "react";


const ACCOMMODATION_TYPE = {
  COUNTRY_HOUSE: "CASAS RURALES",
  HOTEL: "HOTELES",
  CAMPING: "CAMPINGS"
}
const data = [
  {
    "type": "CASAS RURALES",
    "location": "Masia Mas Ribas (L’Armentera 1km)",
    "rooms": "No de hab: 5 hab",
    "link": "www.masribas.com"
  },
  {
    "type": "CASAS RURALES",
    "location": "Cuna House (Armentera 1km)",
    "rooms": "No de hab: 5 apartamentos",
    "link": "www.cunaproject.com"
  },
  {
    "type": "CASAS RURALES",
    "location": "Can Mas (St. Pere Pescador 1’5km)",
    "rooms": "No de hab: 8 hab",
    "link": "www.canmas.net"
  },
  {
    "type": "CASAS RURALES",
    "location": "Mas del Mar (St. Pere Pescador 2km)",
    "rooms": "No de hab: 10 hab",
    "link": "http://www.masdelmar.com"
  },
  {
    "type": "CASAS RURALES",
    "location": "Mas del Joncar (St. Pere Pescador 2km)",
    "rooms": "No de hab: 5 hab",
    "link": "www.masdeljoncar.com"
  },
  {
    "type": "CASAS RURALES",
    "location": "Mas Llausas (Saldet 3km)",
    "rooms": "No de hab: 8 hab",
    "link": "www.naturaki.com/es/masllausas"
  },
  {
    "type": "CASAS RURALES",
    "location": "Villas Coll (Sant Martí d’Empúries 5km)",
    "rooms": "Villas individuales",
    "link": "www.villascoll.es"
  },
  {
    "type": "CASAS RURALES",
    "location": "L’Hort de Sant Cebrià (Torroella de Fluvià 6km)",
    "rooms": "No de hab: 5 hab",
    "link": "http://www.hortdesantcebria.com"
  },
  {
    "type": "CASAS RURALES",
    "location": "Can Barrina (Ventalló 7km)",
    "rooms": "No de hab: 10 hab",
    "link": "casabarrina.com/blog/"
  },
  {
    "type": "CASAS RURALES",
    "location": "Can Marisch (Ventallo 7km)",
    "rooms": "No de hab: 11 hab",
    "link": "http://www.canmarisch.com"
  },
  {
    "type": "HOTELES",
    "location": "Hotel El Moli de Sant Pere *** (St. Pere Pescador 2km)",
    "rooms": "No de hab: 8 hab",
    "link": "www.canmas.net"
  },
  {
    "type": "HOTELES",
    "location": "Hotel Can Ceret *** (St. Pere Pescador 2km)",
    "rooms": "No de hab: 10 hab",
    "link": "http://www.canceret.com"
  },
  {
    "type": "HOTELES",
    "location": "Boutique Hotel Can Picó (Pelacalç 2’5 km)",
    "rooms": "",
    "link": "www.casacanpico.com"
  },
  {
    "type": "HOTELES",
    "location": "Petit Hotel Les Hamaques (Viladamat 3 km)",
    "rooms": "No de hab: 7 hab",
    "link": "www.leshamaques.com"
  },
  {
    "type": "HOTELES",
    "location": "Can Dispes (Viladamat 4km)",
    "rooms": "No de hab: 7 hab",
    "link": "www.candispes.com"
  },
  {
    "type": "HOTELES",
    "location": "Hotel L’Escala Centre ** (L’escala 8km)",
    "rooms": "",
    "link": "www.hotelescalacentre.com"
  },
  {
    "type": "HOTELES",
    "location": "Molí de l’Escala (L’escala 8km)",
    "rooms": "No de hab: 8 hab",
    "link": "www.molidelescala.com"
  },
  {
    "type": "HOTELES",
    "location": "Hostal Empúries ****(Empúries 8km)",
    "rooms": "",
    "link": "www.hostalempuries.com"
  },
  {
    "type": "HOTELES",
    "location": "RVHotels Nieves Mar (L’Escala 8km)",
    "rooms": "",
    "link": "www.hotelnievesmar.es"
  },
  {
    "type": "HOTELES",
    "location": "Hotel Aires de l’Empordà (Vilacolum, 8km)",
    "rooms": "No de hab: 14 hab",
    "link": "www.hotelairesdelemporda.com"
  },
  {
    "type": "HOTELES",
    "location": "Hostel l’Esplai Viladamat (Viladamat 8,7km)",
    "rooms": "No de hab: 26 hab",
    "link": "http://www.hostalesplai.com/es/"
  },
  {
    "type": "HOTELES",
    "location": "Hotel Emporium *** (Castelló d’Empúries 11km)",
    "rooms": "No de hab: 40 hab",
    "link": "www.emporiumhotel.com/ca"
  },
  {
    "type": "HOTELES",
    "location": "B&B Figueres (Figueres 20km)",
    "rooms": "",
    "link": "www.hotel-bb.com/es"
  },
  {
    "type": "CAMPINGS",
    "location": "La Ballena Alegre (St Pere Pescador 2km)",
    "rooms": "rooms: 336 bungalows",
    "link": "www.ballena-alegre.com"
  },
  {
    "type": "CAMPINGS",
    "location": "Camping Riu (St Pere Pescador 2km)",
    "rooms": "rooms: 79 bungalows",
    "link": "www.campingriu.com"
  },
  {
    "type": "CAMPINGS",
    "location": "Camping Aquarius (St Pere Pescador 2km)",
    "rooms": "rooms: 7 bungalows",
    "link": "www.aquarius.es"
  },
  {
    "type": "CAMPINGS",
    "location": "Camping Las Palmeras (St Pere Pescador 2km)",
    "rooms": "rooms: 30 bungalows",
    "link": "www.campinglaspalmeras.com"
  },
  {
    "type": "CAMPINGS",
    "location": "Camping Las Dunas (St. Pere Pescador 2km)",
    "rooms": "rooms: 150 bungalows",
    "link": "www.campinglasdunas.com"
  },
  {
    "type": "CAMPINGS",
    "location": "Camping La Gaviota (St. Pere Pescador 2km)",
    "rooms": "",
    "link": "www.lagaviota.com"
  }
]

const countryHouses = data.filter(it => it.type === ACCOMMODATION_TYPE.COUNTRY_HOUSE)
const hotels = data.filter(it => it.type === ACCOMMODATION_TYPE.HOTEL)
const campings = data.filter(it => it.type === ACCOMMODATION_TYPE.CAMPING)

const Accommodation = () => {

  return (
    <Fragment>
      <Header/>
      <Box className="accomodation picture"></Box>
      <Container className="section">
        <Stack mt={4}>
          <Typography align="center" component="h1" variant="janetH1">Alojamiento</Typography>
          <WeddingDivider />
        </Stack>
        <Stack mt={4}>
          <Typography align="center" component="p" variant="p">
            Os dejamos a continuación varias opciones para los que queráis alojarse cerca del lugar de la boda
          </Typography>
          <Typography align="center" component="p" variant="p">
            Os marcamos los Kms que hay hasta el Castillo de Cortal Gran
          </Typography>
        </Stack>
        <AccommodationList title="Casas Rurales" data={countryHouses}/>
        <AccommodationList title="Hoteles" data={hotels}/>
        <AccommodationList title="Campings" data={campings}/>
      </Container>
    </Fragment>
  );
}

export default Accommodation;
