import Carousel from "./Carousel";

const data = [
  {src: `${process.env.PUBLIC_URL}/assets/image/indonesia/1_INDONESIA.jpg`},
  {src: `${process.env.PUBLIC_URL}/assets/image/indonesia/2_INDONESIA.jpg`},
  {src: `${process.env.PUBLIC_URL}/assets/image/indonesia/3_INDONESIA.jpg`},
  {src: `${process.env.PUBLIC_URL}/assets/image/indonesia/4_INDONESIA.jpg`},
  {src: `${process.env.PUBLIC_URL}/assets/image/indonesia/5_INDONESIA.jpg`},
  {src: `${process.env.PUBLIC_URL}/assets/image/indonesia/6_INDONESIA.jpg`},
]

const HoneyMoonCarousel = () => {
  return (
    <Carousel data={data}/>
  )
}

export default HoneyMoonCarousel
