import './App.css';
import 'flickity/css/flickity.css'
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {onAuthChanged, signIn} from "./services/authentication";
import {useEffect, useState} from "react";
import {getUser, getUserAnonymously, PERMISSIONS} from "./services/firestore";
import UserContext from "./contexts/UserContext";
import Accommodation from "./pages/Accommodation";
import ScrollToTop from "./components/scroll-to-top/Scroll-To-Top";
import Footer from "./components/footer/Footer";
import Photos from "./pages/Photos";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {

  const [user, setUser] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    const _find = async () => {
      const token = window.location.search.slice(1).split("&").find(it => it.split("=")[0] === "t")?.split("=")[1]
      if (token){
        const user = await getUserAnonymously(token)
        await signIn(user.email, user.password)
      }
    }
    _find()
    return onAuthChanged(async userAuth => {
      if (userAuth){
        const user = await getUser(userAuth.uid).catch((e)=> console.log(`From getUser: ${JSON.stringify(e)}`))
        const email = user.email ? user.email : userAuth.email
        setUser({...user, email})
      }else{
        setUser()
      }
    })
  }, [])

  useEffect(() => {
    if (user && user.permissions.indexOf(PERMISSIONS.READ_PHOTOS) >= 0){
      navigate("/photos", { replace: true });
    }
  // eslint-disable-next-line
  }, [user])

  return (
    <Box>
      <UserContext.Provider value={user}>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="accommodation" element={<Accommodation />} />
          {
            user && user.permissions &&
            user.permissions.indexOf(PERMISSIONS.WRITE_USERS) >= 0 && (
              <Route path="admin" element={<Admin />} />
            )
          }
          {user && user.permissions.indexOf(PERMISSIONS.READ_PHOTOS) >= 0 && (
            <Route path="photos" element={<Photos />} />
          )}
            )
          }
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </UserContext.Provider>
      <Container className="section">
        <Footer />
      </Container>
    </Box>
  );
}

export default App;
