import './Photos.css';
import {Box, Container, Stack, Tab, Tabs, Typography} from "@mui/material";
import Header from "../components/header/Header";
import {Fragment, useState} from "react";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Album from "./Albums";
import WeddingVideo from "../components/video/WeddingVideo";
import WeddingDivider from "../components/wedding-divider/Wedding-Divider";
import {albums} from "../services/IndexDb";

const VIDEO = {
  TRAILER: {
    id: 845661604
  },
  MOVIE: {
    id: 846354451
  },
  DANCE: {
    id: 855458879
  }
}


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

const Photos = () => {

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Fragment>
      <Header/>
      <Box className="photos picture"></Box>
      <Container className="section">
        <Box sx={{borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={true}
            allowScrollButtonsMobile>
            <Tab label={"Video"} />
            <Tab label={albums.PREWEDDING} />
            <Tab label={albums.CEREMONY} />
            <Tab label={albums.REPORTAGE} />
            <Tab label={albums.APPETIZER} />
            <Tab label={albums.DINNER_PARTY.replace("&amp;", " & ")} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
          <Stack sx={{mt: 8}}>
            <Box>
              <Typography align="center" component="h1" variant="janetH1">Trailer</Typography>
              <WeddingDivider />
            </Box>
            <Box sx={{mt: 6}}>
              <WeddingVideo videoId={VIDEO.TRAILER.id} />
            </Box>
          </Stack>
          <Stack sx={{mt: 8}}>
            <Box>
              <Typography align="center" component="h1" variant="janetH1">Video completo</Typography>
              <WeddingDivider />
            </Box>
            <Box sx={{mt: 6}}>
              <WeddingVideo videoId={VIDEO.MOVIE.id} />
            </Box>
          </Stack>
          <Stack sx={{mt: 8}}>
            <Box>
              <Typography align="center" component="h1" variant="janetH1">Video baile</Typography>
              <WeddingDivider />
            </Box>
            <Box sx={{mt: 6}}>
              <WeddingVideo videoId={VIDEO.DANCE.id} />
            </Box>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <Album name={albums.PREWEDDING}/>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <Album name={albums.CEREMONY}/>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3}>
          <Album name={albums.REPORTAGE}/>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={4}>
          <Album name={albums.APPETIZER}/>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={5}>
          <Album name={albums.DINNER_PARTY}/>
        </CustomTabPanel>
      </Container>
    </Fragment>
  );
}

export default Photos;
