import WeddingTable from "../wedding-table/Wedding-Table";
import {getAdmins, savePermission, saveUser, deleteUser} from "../../services/firestore";
import {Fragment, useEffect, useState} from "react";
import {onAuthChanged} from "../../services/authentication";

const AdminList = () => {

  const [admins, setAdmins] = useState([])
  const _getAdmins = async () => {
    const users = await getAdmins();
    setAdmins(users)
  }

  useEffect(() => {
    return onAuthChanged(user => {
      if (user){
        _getAdmins()
      }else{
        setAdmins([])
      }
    })
  }, [])

  const onRowUpdateHandler = async (admin, index) => {
    const storedAdmin = await saveUser(admin)
    await savePermission({id: storedAdmin.email, permissions: admin.permissions})
    setAdmins([
      ...admins.slice(0, index),
      {...storedAdmin},
      ...admins.slice(index + 1)
    ])
  }

  const onNewRowHandler = async admin => {
    const storedAdmin = await saveUser(admin)
    await savePermission({id: storedAdmin.email, permissions: admin.permissions})
    setAdmins([...admins, storedAdmin])
  }
  const onRowDeleteHandler = async (admin, index) => {
    await deleteUser(admin.id)
    setAdmins([
      ...admins.slice(0, index),
      ...admins.slice(index + 1)
    ])
  }

  const newItemSample = {
    email: "",
    permissions: []
  }

  const cols = [
    { field: "email", name: "Email", width: "200px" },
    { field: "permissions", name: "Permisos", width: "200px" },
  ]

  return (
    <Fragment>
      <h1>Lista de Admins</h1>
      <WeddingTable
        cols={cols}
        data={admins.sort((a, b) => (a.email < b.email)?-1:1)}
        newItemSample={newItemSample}
        onNewRow={onNewRowHandler}
        onRowUpdate={onRowUpdateHandler}
        onRowDelete={onRowDeleteHandler}
      />
    </Fragment>

  )
}

export default AdminList
