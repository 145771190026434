import WeddingTable from "../wedding-table/Wedding-Table";
import * as firestore from "../../services/firestore";
import {Fragment, useEffect, useState} from "react";
import {onAuthChanged} from "../../services/authentication";
import * as functions from "../../services/functions";
import {Button, Grid, TextField, Typography} from "@mui/material";
import SongList from "../song-list/Song-List"


const whatsappTemplate = "*Fotos - melissa y fernando*\n" +
  "\n" +
  "Ya por fin tenemos las fotos!! 🥁👰‍♀️🤍🤵‍♂️🎉\n" +
  "\n" +
  "Ya podemos revivir ese día que fue tan especial para nosotros \n" +
  "En la nueva sección de fotos podréis encontrar el trailer, el video y todas las fotos ✔️\n" +
  "Cada foto la podréis descargar en alta resolución una vez hagáis click sobre ella. \n" +
  " 🥰❤️\n"

const UserList = () => {

  const [guests, setGuests] = useState([])
  const [defaultPermissions, setDefaultPermissions] = useState([firestore.PERMISSIONS.READ_PHOTOS, firestore.PERMISSIONS.READ_PERMISSIONS])
  useEffect(() => {
    const _getGuests = async () => {
      const result = await firestore.getGuests();
      setGuests(result)
    }
    return onAuthChanged(user => {
      if (user) {
        _getGuests()
      }else{
        setGuests([])
      }
    })
  }, [])


  const resetPermissions = () => {
    guests.filter(user => user.email.endsWith(`@${window.location.hostname}`)).forEach(user => {
      firestore.savePermission({id: user.id, email: user.email, permissions: defaultPermissions})
    })
  }
  const resetPhotos = () => {
    functions.resetPhotos()
  }

  const onRowUpdateHandler = async (user, index) => {

    const storedUser = await functions.updateUser({
      ...user,
      isComing: (typeof user.isComing === 'string')? user.isComing === 'true' : user.isComing,
      hasCompanion: (typeof user.hasCompanion === 'string')? user.hasCompanion === 'true' : user.hasCompanion,
      isAllergic: (typeof user.isAllergic === 'string')? user.isAllergic === 'true' : user.isAllergic,
    })
    setGuests([
      ...guests.slice(0, index),
      {...storedUser.data},
      ...guests.slice(index + 1)
    ])
  }

  const onNewRow = async user => {
    const storedUser = await functions.createUser(user)
    setGuests([...guests, storedUser.data])
  }
  const onRowDelete = async (user, index) => {
    await functions.deleteUser(user)
    setGuests([
      ...guests.slice(0, index),
      ...guests.slice(index + 1)
    ])
  }

  const onFieldClick = (row, fieldValue) => {
    if (row.number){
      const text = encodeURIComponent(whatsappTemplate + fieldValue)
      window.open(`https://wa.me/${row.number}/?text=${text}`)
    }
  }

  const cols = [
    { field: "name", name: "Nombre", width: "150px" },
    { field: "isComing", name: "Confirmado", width: "80px" },
    { field: "number", name: "Número", width: "100px" },
    { field: "url", name: "Url", width: "400px", onFieldClick},
    { field: "hasCompanion", name: "Tiene acompañante", width: "80px" },
    { field: "companionName", name: "Nombre acompañante", width: "150px" },
    { field: "isAllergic", name: "Es alergico", width: "80px" },
    { field: "allergies", name: "Alergias", width: "100px" },
    { field: "song", name: "Cancion", width: "300px" },
    { field: "email", name: "Email", width: "200px" },
    { field: "password", name: "Password", width: "50px" },
    { field: "permissions", name: "Permissions", width: "50px" },
  ]

  const counting = guests.reduce((acc, it) => {
    const tmp = {
      total: acc.total + 1,
      yes: acc.yes + (it.isComing === true ? 1 : 0),
      no: acc.no + (it.isComing === false ? 1 : 0),
      companion: acc.companion + (it.hasCompanion ? 1 : 0),
      noAnswer: acc.noAnswer + (it.isComing === undefined ? 1 : 0)
    }
    return tmp
  }, {total: 0, yes: 0, no: 0, companion: 0, noAnswer: 0})

  return (
    <Fragment>
      <h1>Lista de invitados</h1>
      <Typography>Total: {counting.total}</Typography>
      <Typography>Si: {counting.yes}</Typography>
      <Typography>Acompañantes: {counting.companion}</Typography>
      <Typography>Si + acompañantes: {counting.companion + counting.yes}</Typography>
      <Typography>No: {counting.no}</Typography>
      <Typography>Sin respuesta {counting.noAnswer}</Typography>
      <WeddingTable
        cols={cols}
        data={guests.sort((a, b) => (a.name < b.name)?-1:1)}
        newItemSample={{
          name: "",
        }}
        onNewRow={onNewRow}
        onRowUpdate={onRowUpdateHandler}
        onRowDelete={onRowDelete}
      />
      <SongList songs={guests.filter(it => it.song).map(it => ({...it.song, guest: it.name}))}/>
      <Grid sx={{mt: 4}} container flexDirection="column">
        <Grid item>
          <Button variant="contained" onClick={resetPhotos}>Reset photos</Button>
        </Grid>
        <Grid sx={{mt: 4}}>
          <TextField fullWidth value={defaultPermissions} onChange={e => setDefaultPermissions(e.target.value.split(","))}/>
        </Grid>
        <Grid sx={{mt: 1}}>
          <Button variant="contained" onClick={resetPermissions}>Reset all user permissions</Button>
        </Grid>
      </Grid>
    </Fragment>

  )
}

export default UserList
