import './Wedding-Menu.css';
import {Fragment, useContext, useState} from 'react';
import {Button, Menu, MenuItem} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import CheckIcon from '@mui/icons-material/Check';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {useNavigate} from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import {PERMISSIONS} from "../../services/firestore";
import Authentication from "../authentication/Authentication";
import WeddingMenuItem from "../wedding-menu-item/Wedding-Menu-Item";
import Nightlight from "@mui/icons-material/Nightlight";
import Favorite from "@mui/icons-material/Favorite";
import Notifications from "@mui/icons-material/Notifications";
import MusicNote from "@mui/icons-material/MusicNote";
import Hotel from "@mui/icons-material/Hotel";
import ImageIcon from '@mui/icons-material/Image';

const WeddingMenu = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const user = useContext(UserContext);
  const navigate = useNavigate();
  const onLogoutHandler = () => {
    navigate("/", { replace: true });
  }

  const iconStyle = {
    color: "black"
  }

  return (
    <Fragment key="right">
      <Button onClick={handleClick}><MenuIcon color="secondary"/></Button>
      <Menu
        anchor="right"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose} key="home">
          <WeddingMenuItem to="/" icon={<HomeIcon style={iconStyle}/>} text="Home" />
        </MenuItem>
        <MenuItem onClick={handleClose} key="ourstory">
          <WeddingMenuItem to="/#ourstory" icon={<Favorite style={iconStyle}/>} text="Nuestra historia" />
        </MenuItem>
        <MenuItem onClick={handleClose} key="ourwedding">
          <WeddingMenuItem to="/#ourwedding" icon={<Notifications style={iconStyle}/>} text="Nuestra boda" />
        </MenuItem>
        <MenuItem onClick={handleClose} key="accommodation">
          <WeddingMenuItem to="/accommodation" icon={<Hotel style={iconStyle}/>} text="Alojamiento" />
        </MenuItem>
        {user && (
          <MenuItem onClick={handleClose} key="confirmation">
            <WeddingMenuItem to="/#confirmation" icon={<CheckIcon style={iconStyle}/>} text="Confirmación" />
          </MenuItem>
        )}
        {
          user && (
          <MenuItem onClick={handleClose} key="playlist">
            <WeddingMenuItem to="/#playlist" icon={<MusicNote style={iconStyle}/>} text="Bailandolavida" />
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} key="honeymoon">
          <WeddingMenuItem to="/#honeymoon" icon={<Nightlight style={iconStyle}/>} text="Luna de miel" />
        </MenuItem>
        {
          user && (
            <MenuItem onClick={handleClose} key="photos">
              <WeddingMenuItem to="/photos" icon={<ImageIcon style={iconStyle}/>} text="Photos" />
            </MenuItem>
          )}
        {
          user && user.permissions &&
          user.permissions.indexOf(PERMISSIONS.WRITE_USERS) >= 0 && (
            <MenuItem onClick={handleClose} key="admin">
              <WeddingMenuItem to="/admin" icon={<AdminPanelSettingsIcon style={iconStyle}/>} text="Admin" />
            </MenuItem>
          )}
        <MenuItem onClick={handleClose} key="auth" sx={{ justifyContent: 'right' }}>
          <Authentication onLogout={onLogoutHandler} />
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default WeddingMenu
