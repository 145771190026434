import './Accommodation.css';
import {Box, Button, Stack, Typography} from "@mui/material";
import WeddingDivider from "../wedding-divider/Wedding-Divider";
import {useNavigate} from "react-router-dom";

const Accommodation = () => {
  const navigate = useNavigate()

  return (
    <Stack>
      <Box>
        <Typography align="center" component="h1" variant="janetH1">Alojamiento</Typography>
        <WeddingDivider />
      </Box>
      <Box sx={{mt: 8}}>
        <Typography align="center" component="p" variant="p">
          Os dejamos a continuación varias opciones para los que queráis alojarse cerca del lugar de la boda
        </Typography>
      </Box>
      <Box className="text-center" sx={{mt: 4}}>
        <Button variant="contained" onClick={() => navigate('accommodation')}>Ver alojamientos</Button>
      </Box>
    </Stack>
  )
}

export default Accommodation
