import {Fragment, useEffect, useState} from "react";
import WeddingTable from "../wedding-table/Wedding-Table";
import {Button} from "@mui/material";

const SongList = ({songs = []}) => {

  const [csvLink, setCsvLink] = useState(``)

  useEffect(() => {
    const uri = "data:text/csv;charset=utf-8,Guest,Name,Artist\n" + encodeURIComponent(songs.map(s => `"${s.guest}","${s.name}","${s.artist}"`).join("\n"));
    setCsvLink(uri)
  }, [songs])

  const cols = [
      { field: "guest", name: "Invitado", width: "150px" },
      { field: "name", name: "Nombre", width: "150px" },
      { field: "artist", name: "Artista", width: "80px" },
    ]

    return (
      <Fragment>
      <h1>Lista de canciones</h1>
      <WeddingTable
        cols={cols}
        data={songs.sort((a, b) => (a.name < b.name)?-1:1)}
      />
      <Button variant="outlined" href={csvLink}>Download</Button>
    </Fragment>
    )
}

export default SongList