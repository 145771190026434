import {Box, IconButton, Modal} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const WeddingModal = ({open, onConfirm, onCancel}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal message">
        <Box className="row">
          <span>No se podrá recuperar una vez eliminado, ¿Borrar?</span>
        </Box>
        <Box className="row justify-end">
          <IconButton color="success" onClick={onConfirm}><CheckCircleIcon/></IconButton>
          <IconButton color="error" onClick={onCancel}><CancelIcon /></IconButton>
        </Box>
      </Box>
    </Modal>
  )
}

export default WeddingModal
