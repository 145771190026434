import {Fragment} from "react";
import './PrivacyPolicy.css';
import Header from "./../components/header/Header";
import {Box, Container, Typography} from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Header />
      <Box className="privacy picture"></Box>
      <Container className="section">
        <Typography>
          This Privacy Policy explains how we collect, use, disclose, and safeguard your personal data when you visit our website [YourWebsiteURL.com] or use our services, in compliance with the General Data Protection Regulation (GDPR).

          Information We Collect
          We may collect personal data from you when you voluntarily provide it to us or when you use our website. The types of personal data we may collect include:
          Contact information (such as name, email address, phone number)
          Demographic information (such as age, gender)
          Log files, IP addresses, and other information about your device and how you interact with our website
          Information provided through the use of cookies and similar technologies
          Use of Personal Data
          We use the personal data we collect for the following purposes:
          To provide and improve our products and services
          To respond to your inquiries, questions, or comments
          To personalize and enhance your experience on our website
          To send you marketing communications, updates, and promotional materials if you have opted in to receive them
          To detect, investigate, and prevent fraudulent or unauthorized activity
          Legal Basis for Processing Personal Data
          We will only process your personal data if we have a valid legal basis to do so, which may include:
          Your consent
          The necessity of processing for the performance of a contract
          Compliance with a legal obligation
          Protection of vital interests
          Legitimate interests pursued by us or a third party
          Data Retention
          We will retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.

          Data Sharing and Disclosure
          We may share your personal data with third parties, including service providers, business partners, and regulatory authorities, in the following circumstances:

          To provide and improve our products and services
          To comply with legal obligations or respond to lawful requests from public authorities
          To protect our rights, privacy, safety, or property, and that of our users or others
          With your consent or at your direction
          International Data Transfers
          If we transfer your personal data to countries outside the European Economic Area (EEA), we will ensure appropriate safeguards are in place to protect your data in accordance with applicable data protection laws.

          Your Rights
          You have the right to access, update, correct, and delete your personal data. You may also have the right to restrict or object to certain processing activities. To exercise your rights or for any privacy-related concerns, please contact us using the information provided below.

          Security Measures
          We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction.

          Updates to this Privacy Policy
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. We recommend reviewing this Privacy Policy periodically for any updates.

          Contact Us
          If you have any questions, comments, or requests regarding this Privacy Policy or our privacy practices, please contact us at fernandoruedaoliva@gmail.com
        </Typography>
      </Container>
    </Fragment>
  );
}

export default PrivacyPolicy;
