import './Our-Story.css'
import {Box, Grid, Stack, Typography} from "@mui/material";
import WeddingDivider from "../wedding-divider/Wedding-Divider";
import TripsCarousel from "../carousel/Trips-Carousel";
import WeddingVideo from "../video/WeddingVideo";

const OurStory = () => {
  return (
    <Stack className="our-story">
      <Box>
        <Typography align="center" component="h1" variant="janetH1">Nuestra historia</Typography>
        <WeddingDivider />
      </Box>
      <Stack sx={{mt: 8}}>
        <Box>
          <WeddingVideo videoId={763094188} />
        </Box>
        <Grid container sx={{mt: 4}}>
          <Grid item sm={6}  sx={{pl: 2, pr: 2}}>
            <Typography component="h3" variant="h3" sx={{fontWeight: 'bold'}}>
              Bailando…¡Así nos conocimos!
            </Typography>
            <Typography component="p" variant="p" sx={{mt: 2}}>
              Como muchos sabéis compartimos una de nuestras aficiones preferidas, el baile, y como no, no nos podríamos haber conocido de otro modo que en una clase de bachata…de esto hará unos 4 años y tras muchas historias y momentos, emprendemos este gran viaje juntos.
            </Typography>
          </Grid>
          <Grid item sm={6} sx={{pl: 2, pr: 2}}>
            <Typography component="h3" variant="h3" sx={{fontWeight: 'bold', '@media (max-width: 600px)': {mt: 4}}}>
              Tic Tac, Tic Tac…¡Se viene Bodorrio!
            </Typography>
            <Typography component="p" variant="p" sx={{mt: 2}}>
              Pero antes queremos compartiros algunas imágenes de todos esos momentos que hemos pasado juntos.

              Ya estamos deseando que nos acompañéis en uno de los días más importantes de nuestras vidas, ¡nuestra boda!
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack sx={{mt: 4}}>
        <TripsCarousel />
      </Stack>
    </Stack>
  )
}

export default OurStory
