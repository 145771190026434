import './Photos.css';
import {Container, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import * as functions from "../services/functions";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import InfiniteScroll from "react-infinite-scroll-component";
import {AlbumIndexDb} from "../services/IndexDb";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import WeddingLoader from "../components/wedding-loader/Wedding-Loader";
import * as firestore from "../services/firestore";

let loading = false
const PAGE_SIZE = 10

const Album = ({name}) => {

  const [images, setImages] = useState([])
  const [index, setIndex] = useState(-1);
  const [nextPage, setNextPage] = useState(1);
  const [totalImagesLength, setTotalImagesLength] = useState();
  const [lastItem, setLastItem] = useState();
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    trackPromise(searchPhotos(0))
// eslint-disable-next-line
  }, [])

  const searchPhotos = async (page) => {
    if(!loading){
      loading = true

      let albumTotals = totalImagesLength
      if (!albumTotals){
        const indexedTotals = await AlbumIndexDb.getAlbumTotals(name).catch(e => console.log(e))
        albumTotals = indexedTotals?.items
      }

      const indexedAlbumPage = await AlbumIndexDb.getAlbumPage(name, page).catch(e => console.log(e))
      let cachedMedia = indexedAlbumPage?.items
      if (!cachedMedia){
        const {data: media, lastItem: returnedLastItem} = await firestore.getPhotos(name, PAGE_SIZE, lastItem)
        setLastItem(returnedLastItem)
        cachedMedia = await AlbumIndexDb.saveAlbumPage(name, page, media).catch(e => console.log(e))

        if (!albumTotals){
          const totalImagesCount = await firestore.countAlbumPhotos(name)
          albumTotals = await AlbumIndexDb.saveAlbumTotals(name, totalImagesCount).catch(e => console.log(e))
        }
      }

      if(!totalImagesLength && albumTotals){
        setTotalImagesLength(albumTotals)
      }

      const allRequestedImages = cachedMedia.map(image => ({
        id: image.id,
        src: "data:image/jpeg;base64, " + image.base64,
        width: image.width > image.height ? 450 : 300,
        height: image.width > image.height ? 300 : 450
      }))

      const newImages = [...images, ...allRequestedImages]
      setImages(newImages)

      const newPageIndex = page + 1
      setNextPage(newPageIndex)
    }
    loading = false
  }

  const getDownloadUrl = async (index) => {
    const photo = images[index]
    if (photo.src.indexOf("https://media-cdn-us-west-2.gopro.com") !== 0){
      const response = await functions.downloadById({photoId: photo.id})
      const newPhoto = response.data["_embedded"].files[0]
      const newImages = [
        ...images.slice(0, index),
        {
          ...photo,
          download: newPhoto.url,
        },
        ...images.slice(index + 1)
      ]
      setImages(newImages)
    }
  }

  const onChangeIndexHandler = async (e) => {
    const {index} = e
    getDownloadUrl(index)
    setIndex(index)
  }

  return promiseInProgress ? <WeddingLoader/> : (
    <Container className="section">
      <InfiniteScroll
        dataLength={images.length}
        next={() => searchPhotos(nextPage)}
        hasMore={images.length < totalImagesLength}
        loader={<WeddingLoader/>}
        scrollThreshold={0.5}
        style={{ overflow: "hidden" }}
        endMessage={
            <Typography sx={{textAlign: 'center'}}>¡¡Llegaste al final!!</Typography>
        }
      >
        <PhotoAlbum layout="rows" photos={images} onClick={onChangeIndexHandler} />
      </InfiniteScroll>

      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        on={{
          view: onChangeIndexHandler
        }}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Download, Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </Container>
  );
}

export default Album;
