import './Carousel-Item.css'
import {Box, Typography} from "@mui/material";

const CarouselItem = ({src, text, subText}) => {
  return (
    <Box className="item" sx={{p: 2}}>
      <img alt="img1" src={src}/>
      {text && <Typography align="center" component="p" variant="pXs" sx={{fontStyle: 'italic', mt: 2}}>{text}</Typography>}
      {subText && <Typography align="center" component="p" variant="janetP" sx={{mt: 1}}>{subText}</Typography>}
    </Box>
  )
}

export default CarouselItem
