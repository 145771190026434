import {Fragment, useContext} from "react";
import './Home.css';
import Header from "./../components/header/Header";
import OurStory from "../components/our-story/Our-Story";
import OurWedding from "../components/our-wedding/Our-Wedding";
import Accommodation from "../components/accommodation/Accommodation";
import Confirmation from "../components/confirmation/Confirmation";
import HoneyMoon from "../components/honey-moon/Honey-Moon";
import PlayListAdder from "../components/play-list-adder/Play-List-Adder";
import UserContext from "../contexts/UserContext";
import {Container} from "@mui/material";
import Presentation from "../components/presentation/Presentation";

const Home = () => {
  const user = useContext(UserContext);
  return (
    <Fragment>
      <Header />
      <Presentation/>

      <Container id="ourstory" className="section">
        <OurStory />
      </Container>
      <Container id="ourwedding" className="section" sx={{backgroundColor: 'background.secondary'}}>
        <OurWedding />
      </Container>
      <Container className="section">
        <Accommodation/>
      </Container>
      {
        user && (
          <Container id="confirmation" className="section" sx={{backgroundColor: 'background.secondary'}}>
            <Confirmation/>
          </Container>
        )
      }
      <Container id="honeymoon" className="section" sx={!user?{backgroundColor: 'background.secondary'}:{}}>
        <HoneyMoon/>
      </Container>
      {
        user && (
          <Container id="playlist" className="section" sx={{backgroundColor: 'background.secondary'}}>
            <PlayListAdder/>
          </Container>
        )
      }
    </Fragment>
  );
}

export default Home;
