import {createTheme} from "@mui/material/styles";

const primaryBackgroundColor = "#fff"
const secondaryBackgroundColor = "#f6ece8"
// const primaryColor = '#EABD98'
const primaryColor = '#DDA891'
const secondaryColor = "#fff"
// const thirdColor = "#d5aa94"

const theme = createTheme({
  typography: {
    fontFamily: ["Times New Roman", "Janeth Ville"].join(','),
    h1:{
      fontSize: "5vw",
      "@media (max-width: 850px)": {
        fontSize: "8vw",
      }
    },
    janetH1: {
      fontFamily: "Janeth Ville",
      fontSize: "5vw",
      "@media (max-width: 850px)": {
        fontSize: "10vw",
      }
    },
    janetH2: {
      fontFamily: "Janeth Ville",
      fontWeight: "normal",
      fontSize: "4vw",
      "@media (max-width: 850px)": {
        fontSize: "8vw",
      }
    },
    janet:{
      fontFamily: "Janeth Ville",
    },
    h2:{
      fontSize: "3vw",
      "@media (max-width: 850px)": {
        fontSize: "4vw",
      }
    },
    button: {
      textTransform: 'none',
      width: "100%",
      maxWidth: "400px",
      fontSize: "1.2rem",
      "@media (max-width: 850px)": {
        fontSize: "1.2rem",
      },
    },
    h3: {
      fontSize: "1.5rem",
      "@media (max-width: 850px)": {
        fontSize: "1.4rem",
      },
    },
    p: {
      fontSize: "1.4rem",
      lineHeight: "1.2",
      "@media (max-width: 850px)": {
        fontSize: "1.2rem",
      },
    },
    pXs: {
      fontSize: "1rem",
    },
    pXxs: {
      fontSize: "0.7rem",
    },
    janetP: {
      fontFamily: "Janeth Ville",
      fontSize: "2rem",
      "@media (max-width: 850px)": {
        fontSize: "1.8rem",
      }
    }
  },
  palette: {
    background: {
      default: primaryBackgroundColor,
      secondary: secondaryBackgroundColor,
    },
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    custom: {
      menu: '#DDA891',
      bgSecondary: '#f00'
    }
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input.Mui-disabled': {
            textAlign: "center",
            textFillColor: "black"
          },
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: "40px !important",
          boxShadow: "none !important",
          maxWidth: "200px !important"
        },
      }
    }
  }
});

export default theme
