import {initializeApp} from "firebase/app";
import {initializeAppCheck, ReCaptchaV3Provider} from "@firebase/app-check";

export let firebase;

let siteKey
if (window.location.hostname === 'theferandmel.wedding') {
  siteKey = "6Lfz76shAAAAAIDXco8TBJX13Y2JUI4lUdj6lQSu"
  firebase = initializeApp({
    apiKey: "AIzaSyDdiAwSwBkC71wuwDJSGMTYnlqjdoLjeY8",
    authDomain: "theferandmel.wedding",
    projectId: "theferandmel",
    storageBucket: "theferandmel.appspot.com",
    messagingSenderId: "679617754570",
    appId: "1:679617754570:web:03ad8d59fa802a0db10b33"
  });
}else{

  siteKey = "6LcY8KQiAAAAAEMWy0Xc3Scz2eEBsizXhGgQ3-m3"
  firebase = initializeApp({
    apiKey: "AIzaSyDdiAwSwBkC71wuwDJSGMTYnlqjdoLjeY8",
    authDomain: "bailandolavida.com",
    projectId: "theferandmel",
    storageBucket: "theferandmel.appspot.com",
    messagingSenderId: "679617754570",
    appId: "1:679617754570:web:03ad8d59fa802a0db10b33"
  });
}


// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider(siteKey),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
