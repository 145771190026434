let db
const DB_NAME="bailandolavida"
export const albums = {
  DINNER_PARTY: "Banquete&amp;Fiesta",
  PREWEDDING: "Preparativos",
  CEREMONY: "Ceremonia",
  REPORTAGE: "Reportaje",
  APPETIZER: "Aperitivo",
}

const stores = [
  albums.PREWEDDING,
  albums.CEREMONY,
  albums.REPORTAGE,
  albums.APPETIZER,
  albums.DINNER_PARTY,
]


const initStore = () => {
  const request = window.indexedDB.open(DB_NAME, 3);
  request.onerror = (event) => {
    console.error(`Why didn't you allow my web app to use IndexedDB?!: ${event.target.errorCode}`);
  };
  request.onsuccess = (event) => {
    console.log(`Succesfully openned ${DB_NAME}`)
    db = event.target.result;
  };

  request.onupgradeneeded = (event) => {
    stores.forEach(storeName => {
      console.log(`Creating Object Store: ${storeName}`)
      const db = event.target.result;
      db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true })
    })
  };
}
initStore()


const getObjectStore = (store_name, mode = 'readwrite') => {
  const tx = db.transaction([store_name], mode);
  return tx.objectStore(store_name);
}

const saveAlbumPage = (albumId, pageNumber, items) => {
  const saveRequest = getObjectStore(albumId).add({id: pageNumber, items});
  return new Promise((resolve, reject) => {
    saveRequest.onsuccess = () => {
      resolve(items)
    }
    saveRequest.onerror = (e) => {
      reject(`Error saving album ${albumId} id: ${pageNumber}: ${e.target.error}`)
    }
  })
}

const getAlbumPage = (albumId, page) => {
  const getRequest = getObjectStore(albumId).get(page);
  return new Promise((resolve, reject) => {
    getRequest.onsuccess = () => {
      if(!getRequest.result){
        reject(`Error retrieving album ${albumId} id: ${page}`)
      }else{
        resolve(getRequest.result)
      }
    }
    getRequest.onerror = (e) => {
      reject(`Error retrieving album ${albumId} id: ${page}: ${e.target.error}`)
    }
  })
}

const TOTALS_ID = "totals"
const saveAlbumTotals = (albumId, totals) => {
  return saveAlbumPage(albumId, TOTALS_ID, totals)
}
const getAlbumTotals = (albumsId) => {
  return getAlbumPage(albumsId, TOTALS_ID)
}

export const AlbumIndexDb = {
  saveAlbumTotals,
  getAlbumTotals,
  saveAlbumPage,
  getAlbumPage,
}
