import './Wedding-Table.css';
import {
  Box,
  Button,
  capitalize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import Paper from '@mui/material/Paper';
import {Fragment, useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WeddingModal from "../modal/Wedding-Modal";
import AddIcon from "@mui/icons-material/Add";


const tableCellStyles = {p: 0, border: "none"}
const actionButtonStyles = {width: "50px"}
const actionButtonBoxStyles = {width: "130px"}

const WeddingTable = ({data, cols, newItemSample = {}, onNewRow, onRowDelete, onRowUpdate}) => {

  const [rows, setRows] = useState([])
  useEffect(() => {
    const dataToRow = (item) => {
      return {
        data: {...item},
        actions: {
          delete: !!onRowUpdate,
          edit: !!onRowDelete
        },
        isEditing: false,
        draft: {...item}
      }
    }
    const dataToRows = data => {
      return data.map(r => (dataToRow(r)))
    }
    setRows(dataToRows(data))
  // eslint-disable-next-line
  }, [data])
  const [newItemSampleDraft, setNewItemSampleDraft] = useState(newItemSample)
  const [rowIndexToBeDeleted, setRowIndexToBeDeleted] = useState()

  //Creating
  const onNewHandler = item => {
    onNewRow(item)
    setNewItemSampleDraft(newItemSample)
  }
  const onChangeSample = (field, newValue) => {
    let itemValue = newValue
    if (Array.isArray(newItemSampleDraft[field])){
      itemValue = newValue.split(",")
    }
    setNewItemSampleDraft({...newItemSampleDraft, [field]: itemValue})
  }

  const updateRow = (row, index) => {
    const newRows = [
      ...rows.slice(0, index),
      {...row},
      ...rows.slice(index + 1)
    ]
    setRows(newRows)
  }

  //Editing
  const onEditClickHandler = (row, index) => {
    row.isEditing = !row.isEditing
    updateRow(row, index)
  }

  const onChangeRow = (row, index, field, newValue) => {
    let itemValue = newValue
    if (Array.isArray(row.draft[field])){
      itemValue = newValue.split(",")
    }
    const newRows = [
      ...rows.slice(0, index),
      {...row, draft: {...row.draft, [field]: itemValue}},
      ...rows.slice(index + 1)
    ]
    setRows(newRows)
  }

  const onEditCancelHandler = (row, index) => {
    row.isEditing = !row.isEditing
    row.draft = {...row.data}
    updateRow(row, index)
  }

  const onConfirmEditClickHandler = async (row, index) => {
    const item = {...row.data, ...row.draft}
    onRowUpdate(item, index)
  }

  //Deleting
  const onDeleteClickHandler = async (row, index) => {
    setRowIndexToBeDeleted(index)
  }

  const onClickConfirmDeletion = async () => {
    const row = rows[rowIndexToBeDeleted]
    onRowDelete(row.data, rowIndexToBeDeleted)
    setRowIndexToBeDeleted()
  }

  const onClickCancelDeletion = async () => {
    setRowIndexToBeDeleted()
  }

  const fillRow = (row) => {
    const missingColumns = cols
      .filter(c => !Object.keys(row).find(r => r === c.field))
      .reduce( (acc, curr) => {
        acc[curr.field] = ""
        return acc
      }, {})
    return {
     ...row,
      ...missingColumns
    }
  }

  return (
    <Fragment>
      <TableContainer component={Paper} className="wedding-table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {(cols.map((c) => <TableCell key={c.field} sx={tableCellStyles}><TextField sx={{width: c.width}} disabled value={capitalize(c.name)} /></TableCell>))}
              <TableCell sx={tableCellStyles}><TextField disabled value={capitalize("Acciones")} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {
                  Object.keys(fillRow(row.data))
                    .filter(it => it !== "id")
                    .sort((a, b) => {
                      const indexA = cols.findIndex(c => c.field === a)
                      const indexB = cols.findIndex(c => c.field === b)
                      return indexA - indexB
                    }).map( column => {
                      let value = row.draft[column]
                      if (Array.isArray(value)){
                        value = value.join(",")
                      }
                      if (typeof value === 'object'){
                        value = JSON.stringify(value)
                      }
                      const col = cols.find(c => c.field === column)
                      return col ? (
                        <TableCell key={column} sx={tableCellStyles} className={row.isEditing?"editing":"normal"}>
                          <TextField
                            sx={{width: col.width}}
                            value={value}
                            disabled={!row.isEditing}
                            onClick={col.onFieldClick ? () => col.onFieldClick(row.data, value) : null}
                            onChange={(e) => onChangeRow(row, index, column, e.target.value)}
                          />
                        </TableCell>
                      ) : null
                    })
                }
                <TableCell sx={tableCellStyles}>
                  <Box sx={actionButtonBoxStyles}>
                  {
                    row.actions.edit && !row.isEditing && (
                      <Button sx={actionButtonStyles} onClick={() => onEditClickHandler(row, index)}>
                        <EditIcon/>
                      </Button>
                    )
                  }
                  {
                    row.actions.edit && row.isEditing && (
                      <Button sx={actionButtonStyles} onClick={() => onConfirmEditClickHandler(row, index)}>
                        <CheckCircleIcon />
                      </Button>
                    )
                  }
                  {
                    row.actions.delete && !row.isEditing && (
                      <Button sx={actionButtonStyles} onClick={() => onDeleteClickHandler(row, index)}>
                        <DeleteIcon/>
                      </Button>
                    )
                  }
                  {
                    row.actions.delete && row.isEditing && (
                      <Button sx={actionButtonStyles} onClick={() => onEditCancelHandler(row, index)}>
                        <CancelIcon/>
                      </Button>
                    )
                  }
                  </Box>
                </TableCell>
              </TableRow>
            ))}

              {onNewRow && (
                <TableRow
                key="new"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {
                  Object.keys(newItemSampleDraft).sort().map( column => {
                    let value = newItemSampleDraft[column]
                    if (Array.isArray(value)){
                      value = value.join(",")
                    }
                    return (
                      <TableCell key={column} sx={tableCellStyles}>
                        <TextField label={capitalize(column)} value={value} onChange={(e) => onChangeSample(column, e.target.value)}/>
                      </TableCell>
                    )
                  })
                }
                <TableCell sx={tableCellStyles}>
                  <Button onClick={() => onNewHandler(newItemSampleDraft)}>
                    <AddIcon />
                  </Button>
                </TableCell>
              </TableRow>
              )}

          </TableBody>
        </Table>
      </TableContainer>
      <WeddingModal open={rowIndexToBeDeleted >= 0} onConfirm={onClickConfirmDeletion} onCancel={onClickCancelDeletion}/>
    </Fragment>
  )
}

export default WeddingTable;
