import './Wedding-Countdown.css';
import Countdown from "react-countdown";
import {Box, Grid, Typography} from "@mui/material";

const WEDDING_DATE = new Date("2023/04/30 17:00:00")

const Tile = ({value, unit}) => {
  return (
    <Grid item xs={3}>
      <Box className="tile">
        <Typography align="center" component="div" className="value">{value}</Typography>
        <Typography align="center" component="div" variant="janet" className="unit janeth-ville">{unit}</Typography>
      </Box>
    </Grid>
  )
}

const renderer = ({days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return null;
  } else {
    // Render a countdown
    return (
      <Grid container justifyContent="center" className="wedding-countdown">
        <Tile value={days} unit="Días"></Tile>
        <Tile value={hours} unit="Horas"></Tile>
        <Tile value={minutes} unit="Minutos"></Tile>
        <Tile value={seconds} unit="Segundos"></Tile>
      </Grid>
    );
  }
};

const WeddingCountdown = () => {

  return (
    <Countdown renderer={renderer} date={WEDDING_DATE} />
  )
}

export default WeddingCountdown
