import './Admin.css';
import UserList from "../components/user-list/User-List";
import AdminList from "../components/admin-list/Admin-List";
import {Box} from "@mui/material";

const Admin = () => {

  return (
    <Box>
      <Box sx={{mt: 10}}>
        <UserList />
        <AdminList />
      </Box>
    </Box>
  )
}

export default Admin
