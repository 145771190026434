import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, connectAuthEmulator, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import {firebase} from "./firebase";

const auth = getAuth(firebase);
// auth.languageCode = 'es';
// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

if (window.location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, new GoogleAuthProvider())
    return result.user
}

export const signAppOut = () => {
    return signOut(auth)
}

export const onAuthChanged = (onAuthHandler) => {
  return onAuthStateChanged(auth, onAuthHandler)
}

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
}
