import Carousel from "./Carousel";

const data = [
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/1.jpg`, text: "Él feliz con su Snowboard", subText: "Andorra"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/2.jpg`, text: "Bailando por el mundo", subText: "Praga"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/3.jpg`, text: "Una de las 7 maravillas", subText: "Chichén-Itzá, Mx"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/4.jpg`, text: "Ruinas de Milta", subText: "Oaxaca, Mexico"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/5.jpg`, text: "Descubriendo los cenotes", subText: "Valladolid, Mx"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/6.jpg`, text: "Glühwein im weihnachtsmarkt", subText: "Berlín"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/7.jpg`, text: "Remando entre manglares", subText: "Punta Allen"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/8.jpg`, text: "Prost!", subText: "Babarian Style"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/9.jpg`, text: "Yuum bo'otik - Gracias", subText: "Tulum"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/10.jpg`, text: "La isla bonita", subText: "Holbox"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/11.jpg`, text: "Juntos, imparables", subText: "Vuelo Mx"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/12.jpg`, text: "Playas vírgenes", subText: "Menorca"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/13.jpg`, text: "Arenas rojizas", subText: "Menorca"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/14.jpg`, text: "Plaza España", subText: "Sevilla"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/16.jpg`, text: "Noches de verano", subText: "Menorca"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/17.jpg`, text: "Nuestro primer viaje", subText: "Sevilla"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/18.jpg`, text: "Descubriendo playas espectaculares", subText: "Menorca"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/19.jpg`, text: "Puestas de sol", subText: "Menorca"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/20.jpg`, text: "Carnaval por todo lo alto", subText: "Murcia"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/21.jpg`, text: "Nuestra foto preferida", subText: "Antilla"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/22.jpg`, text: "Bajo el calor mexicano", subText: "Campeche, Mx"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/23.jpg`, text: "A todo color", subText: "México"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/24.jpg`, text: "Auténtica casa colonial", subText: "Campeche, Mx"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/25.jpg`, text: "Momentos felices", subText: "Costa Brava"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/26.jpg`, text: "Aventuras nuevas", subText: "Costa Brava"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/27.jpg`, text: "Cenitas especiales ", subText: "Barcelona"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/28.jpg`, text: "Un buen final", subText: "Peñón de Ifach"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/29.jpg`, text: "#lasantamarket", subText: "Costa Brava"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/30.jpg`, text: "Home, Sweet Home", subText: "Barcelona"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/31.jpg`, text: "En el corazón de Atenas", subText: "Grecia"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/32.jpg`, text: "Cañón del sumidero", subText: "Chiapas, Mx"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/33.jpg`, text: "Love is in the air", subText: "Villajoyosa"},
  {src: `${process.env.PUBLIC_URL}/assets/image/carousel/34.jpg`, text: "Sí quiero", subText: "Villa Venecia"},
]

const TripsCarousel = () => {
  return (
    <Carousel data={data}/>
  )
}

export default TripsCarousel
