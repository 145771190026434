import {firebase} from "./firebase";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

const functions = getFunctions(firebase, 'europe-west3');
if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const createUser = httpsCallable(functions, 'createUser');
export const deleteUser = httpsCallable(functions, 'deleteUser');
export const updateUser = httpsCallable(functions, 'updateUser');
export const downloadById = httpsCallable(functions, 'downloadById');
export const resetPhotos = httpsCallable(functions, 'resetPhotos');
