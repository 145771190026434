import './Our-Wedding.css'
import {Box, Grid, Typography} from "@mui/material";
import WeddingDivider from "../wedding-divider/Wedding-Divider";
import WeddingMap from "../wedding-map/Wedding-Map";

const OurWedding = () => {
  return (
    <Grid container className="our-wedding">
      <Grid xs={12} item justifyContent="center">
        <Typography align="center" component="h1" variant="janetH1">Nuestra boda</Typography>
        <WeddingDivider />
      </Grid>
      <Grid item xs={12} container sx={{mt: 8}}>
        <Grid item xs={12} sm={6} sx={{pl: 2, pr: 2}}>
          <Typography component="p" variant="p" sx={{fontWeight: 'bold'}}>CEREMONIA Y BANQUETE</Typography>
          <Typography component="p" variant="p" sx={{mt: 2}}>Fecha: 30 de abril del 2023</Typography>
          <Typography component="p" variant="p" sx={{mt: 1}}>Lugar: Castillo Cortal Gran</Typography>
          <Typography component="p" variant="p" sx={{mt: 1}}>Hora: A las 17:00h</Typography>
          <Typography component="p" variant="p" sx={{mt: 1}}>Código de vestimenta: Formal</Typography>
          <Box className="row justify-center" sx={{mt: 4}}>
            <WeddingMap center={{lat: 42.16879577304185, lng: 3.0932606118652672}} zoom={12} disableDefaultUI/>
          </Box>
        </Grid>
        <Grid item sm={6} sx={{pl: 2, pr: 2, mt: 4}}>
          <Box className="row justify-center">
            <img alt="masia" src={`${process.env.PUBLIC_URL}/assets/image/cortal-gran/CORTAL3.jpeg`} className="picture masia"></img>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography component="p" variant="pXs" sx={{fontStyle: 'italic', mt: 2}}>
              Este precioso castillo se construyó en el S.XV como casa fortificada para la defensa de los ataques de los piratas y los bandoleros. La familia Caramany siempre se ha dedicado a la explotación agrícola y ganadera, y sigue en la actualidad con la explotación de los campos de manzanos que la envuelven. Es un lugar que desprende historia en un entorno mágico.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OurWedding
