import {Grid, Typography} from "@mui/material";
import WeddingLink from "../Wedding-Link/Wedding-Link";

const WeddingMenuItem = ({onClick, to, icon, text}) => {
  return (
    <WeddingLink onClick={onClick} to={to}>
    <Grid container spacing={1}>
      <Grid item>
          {icon}
      </Grid>
      <Grid item>
        <Typography variant="span" sx={{verticalAlign: "text-top", color: "black"}} color="custom.menu">{text}</Typography>
      </Grid>
    </Grid>
    </WeddingLink>
  )
}

export default WeddingMenuItem
