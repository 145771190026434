import {
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  query,
  orderBy,
  getCountFromServer,
  startAfter,
  limit
} from "firebase/firestore";
import {firebase} from "./firebase";

const db = getFirestore(firebase)

const USERS_KEY = "users"
const PERMISSIONS_KEY = "permissions"
const ALBUMS_KEY = "albums"
const PHOTOS_KEY = "photos"

const isAdmin = permissions => {
  return permissions.indexOf(PERMISSIONS.WRITE_ADMINS) >= 0
}

const getPhotoPath = (albumId) => `${ALBUMS_KEY}/${albumId}/${PHOTOS_KEY}`
export const PERMISSIONS = {
  WRITE_USERS: "WRITE_USERS",
  WRITE_ADMINS: "WRITE_ADMINS",
  WRITE_PERMISSIONS: "WRITE_PERMISSIONS",
  READ_PERMISSIONS: "READ_PERMISSIONS",
  READ_PHOTOS: "READ_PHOTOS"
}



if (window.location.hostname === 'localhost') {
  // db.useEmulator('localhost', 8080);
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const saveUser = async (user) => {
  const userId = user.id;
  delete user.id;
  await setDoc(doc(db, USERS_KEY, userId), user);
  user.id = userId
  return user
}

export const getUsers = async () => {
  const users = await getDocs(collection(db, USERS_KEY));
  const permissions = await getAllPermissions()
  const usersWithId = addIdToDocuments(users)
  return usersWithId.map(user => {
    const userPermissions = permissions.find(p => user.id === p.id)
    return {...user, permissions: userPermissions ? userPermissions.permissions : []}
  })
}
export const getUserAnonymously = async (userId) => {
  const user = await getDoc(doc(db, USERS_KEY, userId));
  return addIdToDocument(user)
}
export const getUser = async (userId) => {
  const user = await getDoc(doc(db, USERS_KEY, userId));
  const permissions = await getUserPermissions(userId)
  return {...addIdToDocument(user), permissions: permissions.permissions}
}

export const getGuests = async () => {
  const allUsers = await getUsers()
  return allUsers.filter(g => !isAdmin(g.permissions))
}

export const getAdmins = async () => {
  const allUsers = await getUsers()
  return allUsers.filter(g => isAdmin(g.permissions))
}

export const deleteUser = async (userId) => {
  return await deleteDoc(doc(db, USERS_KEY, userId));
}

export const savePermission = async (permission) => {
  const permissionId = permission.id;
  delete permission.id;
  await setDoc(doc(db, PERMISSIONS_KEY, permissionId), permission);
  permission.id = permissionId
  return permission
}

export const getUserPermissions = async (uid) => {
  const permissions = await getDoc(doc(db, PERMISSIONS_KEY, uid));
  return addIdToDocument(permissions)
}

export const getAllPermissions = async () => {
  const permissions = await getDocs(collection(db, PERMISSIONS_KEY));
  return addIdToDocuments(permissions)
}

export const getPhotos = async (name, pageSize, lastItem) => {
  const ref = collection(db, getPhotoPath(name))
  let next
  if (lastItem) {
    next = query(ref, orderBy('captured_at'), startAfter(lastItem), limit(pageSize))
  }else{
    next = query(ref, orderBy('captured_at'), limit(pageSize))
  }
  const docs = await getDocs(next);
  return {data: addIdToDocuments(docs), lastItem: docs.docs[docs.docs.length-1]}
}

export const countAlbumPhotos = async (albumName) => {
  const snapshot = await getCountFromServer(collection(db, getPhotoPath(albumName)))
  return snapshot.data().count
}

const addIdToDocument = doc => {
  return {...doc.data(), id: doc.id}
}
const addIdToDocuments = docs => {
  const docsWithId = []
  docs.forEach(it => {
    docsWithId.push(addIdToDocument(it))
  })
  return docsWithId
}


