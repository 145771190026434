import {useEffect, useRef} from "react";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import {Box, CircularProgress} from "@mui/material";

const mapStyles = {
  width: "100%",
  height: 350
}

const MyMapComponent = (props) => {
  const ref = useRef();
  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      ...props
    });
  });

  return (
    <div ref={ref} id="map" style={mapStyles} />
  )
}

const WeddingMap = (props) => {
  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <CircularProgress />
      case Status.FAILURE:
        return <Box>Error</Box>
      default:
        return null
    }
  };

  return (
    <Wrapper apiKey={"AIzaSyA5EItKQPveUorKFdNAb1k7ORh_IKqeWYM"} render={render} >
      <MyMapComponent {...props}/>
    </Wrapper>
  )
}

export default WeddingMap
