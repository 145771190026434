import './Presentation.css'
import {Grid, Stack, Typography} from "@mui/material";
import UserContext from "../../contexts/UserContext";
import {useContext} from "react";
import WeddingCountdown from "../wedding-countdown/Wedding-Countdown";

const Presentation = () => {
  const user = useContext(UserContext)

  const UserNameDisplay = () => {
    let userNameDisplay
    if(user){
      if(user && user.name){
        userNameDisplay = user.name
      }
    }
    return (
      userNameDisplay && (<Typography>{`con ${userNameDisplay}`}</Typography>)
    )
  }

  return (
    <>
      <Stack direction="column-reverse" className="presentation">
        <Grid item>
          <Typography sx={{mt: 2}} component="h2" align="center" variant="janetH2" color="white">#Bailandolavida{<UserNameDisplay/>}</Typography>
        </Grid>
      </Stack>
      <WeddingCountdown />
    </>
  )
}

export default Presentation
