import './Play-List-Adder.css';
import {Box, Button, Stack, TextField, Typography} from "@mui/material";
import WeddingDivider from "../wedding-divider/Wedding-Divider";
import UserContext from "../../contexts/UserContext";
import {saveUser} from "../../services/firestore";
import {useContext, useState} from "react";

const PlayListAdder = () => {

  const defaultText = "Enviar"
  const {user} = useContext(UserContext);
  const defaultSong = {name: "", artist: ""}
  const [song, setSong] = useState(user && user.song?user.song:defaultSong)
  const [buttonText, setButtonText] = useState(defaultText)

  const onChange = (field, newValue) => {
    setSong({...song, [field]: newValue})
  }

  const saveSong = async () => {
    await saveUser({...user, song})
    setButtonText("Enviado")
  }

  return (
    <Stack>
      <Box>
        <Typography align="center" component="h1" variant="janetH1">Bailandolavida</Typography>
        <WeddingDivider />
      </Box>
      <Box className="column" sx={{mt: 8}}>
        <Typography align="center" component="p" variant="p">
          ¡Queremos que nuestra boda sea un auténtico fiestón!
        </Typography>
        <Typography align="center" component="p" variant="p">
          Por eso os invitamos a que pongáis canciones que os gustaría escuchar en nuestra fiesta
        </Typography>
        <TextField value={song.artist} sx={{mt: 2, backgroundColor: "secondary.main"}} label="Artista" onChange={(e) => onChange("artist", e.target.value)}></TextField>
        <TextField value={song.name} sx={{mt: 2, backgroundColor: "secondary.main"}} label="Canción" onChange={(e) => onChange("name", e.target.value)}></TextField>
        <Box className="text-center">
          <Button disabled={buttonText !== defaultText} sx={{mt: 2, width: "100%"}} variant="contained" onClick={saveSong}>{buttonText}</Button>
        </Box>
      </Box>
    </Stack>
  )
}

export default PlayListAdder
