import './Confirmation.css';
import {Box, Button, FormGroup, FormLabel, Stack, TextField, Typography} from "@mui/material";
import WeddingDivider from "../wedding-divider/Wedding-Divider";
import WeddingSwitch from "../wedding-switch/Wedding-Switch";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {saveUser} from "../../services/firestore";
import {useContext, useState} from "react";
import UserContext from "../../contexts/UserContext";

const Confirmation = () => {

  const defaultText = "Enviar"
  const [buttonText, setButtonText] = useState(defaultText)
  const user = useContext(UserContext);
  const defaultValues = {
    isComing: false,
    isAllergic: false,
    allergies: '',
    hasCompanion: false,
    companionName: ''
  }
  const [userForm, setUserForm] = useState((user && user.user)?{...defaultValues, ...user.user}:defaultValues)

  const onChange = (field, newValue) => {
    setUserForm({...userForm, [field]: newValue})
  }

  const sendForm = async () => {
    await saveUser({...userForm})
    setButtonText("Enviado")
  }

  return (
    <Stack>
      <Box>
        <Typography align="center" component="h1" variant="janetH1">Confirmación</Typography>
        <WeddingDivider />
      </Box>
      <Stack className="text-left" sx={{ml: "auto", mr: "auto"}}>
        <FormGroup>
          <Box sx={{mt: 8}}>
            <Typography align="center" component="p" variant="p">
              Podéis utilizar este formulario para confirmar vuestra asistencia a la boda.
            </Typography>
            <Typography align="center" component="p" variant="p">
              Si vais a confirmar con acompañante, por favor indicar el nombre
            </Typography>
          </Box>
          <FormLabel component="p">¿Asistirás a la boda?</FormLabel>
          <WeddingSwitch
            icon={<HeartBrokenIcon color="error"/>}
            checkedIcon={<FavoriteIcon color="error"/>}
            checked={userForm.isComing}
            onChange={(c) => onChange('isComing', c.target.checked)}
          />
          {
            userForm.isComing && (
              <>
                <FormLabel component="legend" sx={{mt: 2}}>¿Tienes alguna alergia o intorlerancia?</FormLabel>
                <WeddingSwitch
                  onChange={(c) => onChange('isAllergic', c.target.checked)}
                  checked={userForm.isAllergic}
                />
                {
                  userForm.isAllergic && (
                    <>
                      <FormLabel component="legend" sx={{mt: 2}}>¿Que tipo de alergia o intolerancia tienes?</FormLabel>
                      <TextField
                        sx={{backgroundColor: "secondary.main"}}
                        onChange={(c) => onChange('allergies', c.target.value)}
                        value={userForm.allergies}
                      />
                    </>
                  )
                }
                <FormLabel component="legend" sx={{mt: 2}}>
                  <Typography variant="p">¿Vendrás con acompañante?*</Typography>
                </FormLabel>
                <WeddingSwitch
                  onChange={(c) => onChange('hasCompanion', c.target.checked)}
                  checked={userForm.hasCompanion}
                />
                <Typography>*Si tu acompañante ha recibido invitación no hace falta que le añadas aquí.</Typography>
                {
                  userForm.hasCompanion && (
                    <>
                      <FormLabel component="legend" sx={{mt: 2}}>Nombre y apellidos del acompañante</FormLabel>
                      <TextField
                        sx={{backgroundColor: "secondary.main"}}
                        onChange={(c) => onChange('companionName', c.target.value)}
                        value={userForm.companionName}
                      />
                    </>
                  )
                }
              </>
            )
          }
          <Box className="text-center">
            <Button sx={{mt: 2}} variant="contained" onClick={sendForm} disabled={buttonText !== defaultText}>{buttonText}</Button>
          </Box>
        </FormGroup>
      </Stack>
    </Stack>
  )
}

export default Confirmation
