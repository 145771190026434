import './Wedding-Divider.css'
import {Divider} from "@mui/material";

const WeddingDivider = () => {
  return (
    <Divider className="divider" align="center" sx={{backgroundColor: 'primary.main'}} />
  )
}

export default WeddingDivider
