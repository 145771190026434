import './Footer.css';
import {Box, Link, Typography} from "@mui/material";

const Footer = () => {
  return (
    <Box className="row justify-space-between">
      <Typography align="center" component="p" variant="pXxs">
        Boda Fernando y Melissa
      </Typography>
      <Typography align="center" component="p" variant="pXxs">
        <Link href="/privacy">Todos los derechos reservados</Link>
      </Typography>
    </Box>
  )
}

export default Footer
