import './Header.css';
import {AppBar, Box, Grid, Typography} from "@mui/material";
import HideOnScroll from "../Hide-on-Scroll/Hide-On-Scroll";
import WeddingMenu from "../wedding-menu/Wedding-Menu";
import {useEffect, useState} from "react";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const limitOffSet = 140
  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const appBarStyle = {
    ...{backgroundColor: "rgba(0, 0, 0, 0)", boxShadow: "none"},
    ...(scrollPosition > limitOffSet && {backgroundColor: "rgba(0,0,0, 0.3)"}),
  }

  return (
    <Box className="row justify-space-between header">
      <HideOnScroll>
        <AppBar component="nav" sx={appBarStyle}>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item container justifyContent="center" direction="column" xs={10}>
              <Typography className="title" align="center" color="white" variant="h1">melissa & fernando</Typography>
              <Typography align="center" variant="h2" color="white">30 · 04 · 2023</Typography>
            </Grid>
            <Grid container item xs={1} justifyContent="flex-end">
              <WeddingMenu />
            </Grid>
          </Grid>
        </AppBar>
      </HideOnScroll>
    </Box>
  )
}

export default Header
