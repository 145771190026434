import './Honey-Moon.css';
import {Box, Stack, TextField, Typography} from "@mui/material";
import WeddingDivider from "../wedding-divider/Wedding-Divider";
import HoneyMoonCarousel from "../carousel/Honey-Moon-Carousel";
import {useState} from "react";

const accountNumber = "ES73 2100 2904 0202 2026 9296"

const HoneyMoon = () => {
  const [accountNumberText,  setAccountNumberText] = useState(accountNumber)
  const accountInputHandler = (e) => {
    navigator.clipboard.writeText(e.target.value)
    setAccountNumberText("Número de cuenta copiado")
    setTimeout(() => {
      setAccountNumberText(accountNumber)
    }, 1000)
  }
  return (
    <Stack>
      <Box>
        <Typography align="center" component="h1" variant="janetH1">Luna de miel</Typography>
        <WeddingDivider />
      </Box>
      <Box sx={{mt: 8, mb: 4}} className="column justify-center">
        <Typography align="center" component="p" variant="p">
          El mejor regalo es compartir este día con todos vosotros. Sin embargo, si quéreis participar en nuestro viaje de novios podéis hacerlo aquí
        </Typography>
        <TextField onClick={accountInputHandler} sx={{mt: 4}} disabled value={accountNumberText} label="Número de cuenta"/>
      </Box>
      <HoneyMoonCarousel />
      <Typography sx={{mt:4}} align="center" component="p" variant="p">Queremos descubir el espectacular país asiático de <span style={{fontSize: "2rem",fontFamily: 'Janeth Ville'}}>Indonesia</span></Typography>
    </Stack>
  )
}

export default HoneyMoon
