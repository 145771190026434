import './Wedding-Link.css'
import {HashLink} from "react-router-hash-link";

const WeddingLink = ({onClick, to, children}) => {
  return (
    <HashLink smooth onClick={onClick} className="wedding-link" to={to}>{children}</HashLink>
  )
}

export default WeddingLink
