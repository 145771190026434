import {FormControlLabel, Switch} from "@mui/material";
import {useState} from "react";


const WeddingSwitch = ({checked, onChange, icon, checkedIcon}) => {
  const [isOn, setIsOn] = useState(true)

  const onChangeHandler = (e) => {
    setIsOn(e.target.checked)
    onChange(e)
  }

  const switchProps = {
      checked: checked,
      onChange: onChangeHandler,
      size: "large",
      ...(checked !== undefined ? checked : false),
      ...(icon && {icon: icon}),
      ...(checkedIcon && {checkedIcon: checkedIcon}),
  }

  return (
    <FormControlLabel
      control={
        <Switch
          {...switchProps}
        />
      }
      label={isOn?"Si":"No"}
    />
  )
}

export default WeddingSwitch
